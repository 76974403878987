<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row class="top_l" :gutter="10">
					<el-col :span="6" :xs="24"><el-input v-model="positionId" clearable placeholder="请输入广告位id"></el-input></el-col>
					<el-col :span="18" :xs="24">
						<el-button type="primary" @click="jouShare">搜索</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="dialogAdd = true">新增</el-button>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" style="width: 100%" border stripe>
					<el-table-column prop="positionId" label="广告位id"></el-table-column>
					<el-table-column prop="name" label="广告位名称"></el-table-column>
					<el-table-column prop="cpm" label="价格"></el-table-column>
					<el-table-column prop="callbackSk" label="密钥"></el-table-column>
					<el-table-column label="操作" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
							<el-button size="small" type="primary" plain @click="clcdelete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 新增-广告位 -->
				<el-dialog v-model="dialogAdd" title="新增广告位" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="110px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="广告位ID" prop="positionId"><el-input placeholder="请输入广告位ID" v-model="ruleForm.positionId"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="广告位名称" prop="name"><el-input placeholder="请输入广告位名称" v-model="ruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="价格" prop="cpm"><el-input placeholder="请输入价格" v-model="ruleForm.cpm"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="密钥" prop="callbackSk"><el-input placeholder="请输入密钥" v-model="ruleForm.callbackSk"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改 -->
				<el-dialog v-model="dialogEdit" title="修改广告位" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="110px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="广告位ID" prop="positionId"><el-input placeholder="请输入广告位ID" v-model="EditruleForm.positionId"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="广告位名称" prop="name"><el-input placeholder="请输入广告位名称" v-model="EditruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="价格" prop="cpm"><el-input placeholder="请输入价格" v-model="EditruleForm.cpm"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="密钥" prop="callbackSk"><el-input placeholder="请输入密钥" v-model="EditruleForm.callbackSk"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'advertPositionInfo',
	data() {
		return {
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			positionId: '',
			fullscreenshow: false,
			// 新增
			dialogAdd: false,
			ruleForm: {
				positionId: '',
				cpm: '',
				callbackSk: '',
				name: ''
			},
			rules: {
				positionId: [{ required: true, message: '请输入广告位ID', trigger: 'blur' }],
				name: [{ required: true, message: '请输入广告位名称', trigger: 'blur' }],
				cpm: [{ required: true, message: '请输入价格', trigger: 'blur' }],
				callbackSk: [{ required: true, message: '请输入密钥', trigger: 'blur' }]
			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				positionId: '',
				cpm: '',
				callbackSk: '',
				name: ''
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
		// 获取广告位信息
		this.advertPositionInfoC();
	},
	methods: {
		// 获取广告位信息
		advertPositionInfoC() {
			this.serloading = true;
			this.$HTTP.post(
				'advertPositionInfo/query',
				{
					positionId: this.positionId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			// 获取广告位信息
			this.advertPositionInfoC();
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		// 新增
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'advertPositionInfo/save',
						{
							positionId: this.ruleForm.positionId,
							cpm: this.ruleForm.cpm,
							callbackSk: this.ruleForm.callbackSk,
							name: this.ruleForm.name
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取广告位信息
									this.advertPositionInfoC();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			this.EditruleForm.positionId = row.positionId;
			this.EditruleForm.cpm = row.cpm;
			this.EditruleForm.callbackSk = row.callbackSk;
			this.EditruleForm.id = row.id;
			this.EditruleForm.name = row.name;
			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'advertPositionInfo/update',
						{
							id: this.EditruleForm.id,
							positionId: this.EditruleForm.positionId,
							callbackSk: this.EditruleForm.callbackSk,
							cpm: this.EditruleForm.cpm,
							name: this.EditruleForm.name
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取广告位信息
									this.advertPositionInfoC();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 删除
		clcdelete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$HTTP.post(
						'advertPositionInfo/delete',
						{
							id: row.id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取广告位信息
									this.advertPositionInfoC();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取广告位信息
			this.advertPositionInfoC();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取广告位信息
			this.advertPositionInfoC();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
